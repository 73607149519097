import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "./gallery.scss"

const Gallery = () => {
  const imgList = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "img-list1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "img-list8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3: file(relativePath: { eq: "img-list3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img4: file(relativePath: { eq: "img-list7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img5: file(relativePath: { eq: "img-list5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img6: file(relativePath: { eq: "img-list4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img7: file(relativePath: { eq: "img-list11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img8: file(relativePath: { eq: "img-list10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="wrapper-gallery">
      <div className="block-gallery">
        <div className="container">
          <div className="big-img">
            <img
              alt=""
              className={"img"}
              src={imgList.img1.childImageSharp.fluid.src}
            />
          </div>
          <div className="small-img">
            <img
              alt=""
              className={"img"}
              src={imgList.img2.childImageSharp.fluid.src}
            />
          </div>
        </div>
        <div className="container">
          <div className="normal-img">
            <img
              alt=""
              className={"img"}
              src={imgList.img3.childImageSharp.fluid.src}
            />
          </div>
          <div className="normal-img">
            <img
              alt=""
              className={"img"}
              src={imgList.img4.childImageSharp.fluid.src}
            />
          </div>
        </div>
      </div>
      <div className="block-gallery">
        <div className="container">
          <div className="small-img">
            <img
              alt=""
              className={"img"}
              src={imgList.img5.childImageSharp.fluid.src}
            />
          </div>
          <div className="big-img">
            <img
              alt=""
              className={"img"}
              src={imgList.img6.childImageSharp.fluid.src}
            />
          </div>
        </div>
        <div className="container">
          <div className="normal-img">
            <img
              alt=""
              className={"img"}
              src={imgList.img7.childImageSharp.fluid.src}
            />
          </div>
          <div className="normal-img">
            <img
              alt=""
              className={"img"}
              src={imgList.img8.childImageSharp.fluid.src}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery
